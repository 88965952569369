import {
  Autocomplete,
  Box,
  createFilterOptions,
  TextField,
} from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import {
  UpdateWorkflowRequestMutationVariables,
  WorkflowRequestsWorkflowRequestFragment,
} from 'gql/graphql';
import { TextCuttoff } from 'ui/TextCutoff/TextCuttoff';
import { useEmpAndContactOfAccount } from 'hooks/search-task-contact-hook';
import { useUpdateWorklowRequest } from 'hooks/workflow-request-hooks';
import {
  workflowRequestQueryDocument,
  workflowRequestsQueryDocument,
} from 'api/workflow-requests';
import { useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';

type AssignedToCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
};

export const AssignedToCell = ({ params }: AssignedToCellProps) => {
  const {
    assignedContacts,
    assignedAccount,
    id: workflowRequestId,
    status: workflowStatus,
  } = params.row;
  const queryClient = useQueryClient();
  const { mutate: updateWorkflowRequestMutation } = useUpdateWorklowRequest();
  const { data: originalContactData } = useEmpAndContactOfAccount(
    assignedAccount && assignedContacts?.length === 0
      ? assignedAccount.legacyId
      : undefined,
  );
  const contactDataRef = useRef<LegacyContact[] | undefined>();

  if (originalContactData && originalContactData.length > 0) {
    contactDataRef.current = originalContactData.filter(
      (data) => data.type === 'contact',
    );
  }

  // used to submit the change to the API
  const updateWorkflowRequest = (
    variables: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    updateWorkflowRequestMutation(
      {
        workflowRequestId,
        ...variables,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestsQueryDocument.definitions[0] as any).name.value,
            ],
          });
          queryClient.invalidateQueries({
            queryKey: [
              (workflowRequestQueryDocument.definitions[0] as any).name.value,
            ],
          });
        },
      },
    );
  };

  // used to manage when a contact or employee are selected
  const handleContactChange = (
    event: React.SyntheticEvent,
    value: LegacyContact | null,
  ) => {
    updateWorkflowRequest({
      assignedContactIds: [value?.uuid],
    });
  };

  // used to manage filtering for the Autocomplete
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: LegacyContact) => option.label,
    ignoreCase: true,
  });

  return (
    <Box
      display="flex"
      gap="5px"
      flexDirection="column"
      alignItems="start"
      width="100%"
    >
      {workflowStatus.toUpperCase() === 'DRAFT' &&
      assignedContacts?.length === 0 &&
      contactDataRef.current ? (
        <Autocomplete
          key={assignedAccount?.id}
          sx={{ width: '100%' }}
          options={contactDataRef.current.sort((a, b) =>
            a.type > b.type ? -1 : 1,
          )}
          groupBy={() => 'Contact'}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          filterOptions={filterOptions}
          onChange={handleContactChange}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={(inputParams) => (
            <TextField
              {...inputParams}
              variant="standard"
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => event.stopPropagation()}
              onKeyDown={(event) => event.stopPropagation()}
            />
          )}
        />
      ) : (
        <TextCuttoff
          text={[
            ...(assignedContacts?.map(
              (contact) =>
                `${contact?.firstName || ''} ${contact?.lastName || ''}`,
            ) || []),
          ]}
        />
      )}
    </Box>
  );
};
