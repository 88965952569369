import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { AlertMessage } from '../../utilities/utils';
import { buildHierarchy, ROOT_FOLDER } from './service';
import { GET_ACCOUNTS_OF_CONTACT_API_PATH, SERVICE_ALIASES, GET_DOCUMENT_DETAILS } from '../../constants/constants';
import { Accounts, AccountSelectorProps } from './types';
import RadioButton from '../RadioButton';

export default function AccountSelector (
  {
    updateExplorer,
    selectedRecords = [],
    updateHierarchy,
    updateIsLoading,
    fetchedSmartVaultAccessToken
  }: AccountSelectorProps
) {
  const [linkedAccount, setLinkedAccount] = useState('')
  const [linkedContact, setLinkedContact] = useState('')
  const [selectedAccount, setSelectedAccount] = useState('')
  const [accounts, setAccounts] = useState<Accounts>([])
  const [defaults, setDefaults] = useState(false)
  const multipleFiles = selectedRecords.length > 1
  const sameAccount = selectedRecords.every(
    obj => obj.account_id === selectedRecords[0].account_id
  )

  const fetchAssociationIds = () => {
    const request = Axios.get(`${GET_DOCUMENT_DETAILS}/${selectedRecords[0].id}`);
    request
      .then(response => {
        const accountId = response.data.data.account_id
        const contactId = response.data.data.contact_id
        if (accountId) {
          setLinkedAccount(accountId)
        }
        else if (contactId) {
          setLinkedContact(contactId)
        } else {
          setDefaults(true)
        }
      })
      .catch(error => AlertMessage('error', error.message, 3000))
  }

  const fetchAccountsByContact = () => {
    const request = Axios.get(`${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${linkedContact}`);
    request
      .then(response => {
        setAccounts(response.data.data)
        setDefaults(true);
      })
      .catch(error => AlertMessage('error', error.message, 3000))
  }

  const fetchAccountId = () => {
    if (window.location.href.includes('accountdetails')) {
      setLinkedAccount(window.location.pathname.match(/\d/g)!.join(""))
      return
    }

    if (!multipleFiles && selectedRecords[0]?.account_id) {
      setLinkedAccount(selectedRecords[0].account_id)
      return
    }

    if (!multipleFiles && selectedRecords[0]?.contact_id) {
      setLinkedContact(selectedRecords[0].contact_id)
      return
    }

    if (!multipleFiles && selectedRecords[0]) {
      fetchAssociationIds()
      return
    }

    if (multipleFiles && sameAccount && selectedRecords[0]?.account_id) {
      setLinkedAccount(selectedRecords[0].account_id)
      return
    }

    updateExplorer(`/${ROOT_FOLDER}`);
  }

  const fetchServiceAlias = () => {
    Axios.get(SERVICE_ALIASES, { params: { service_id: 'smartvault', aliasable_type: 'Account', aliasable_id: linkedAccount || selectedAccount } })
      .then(res => {
        if (res.data.data.length) {
          const folderPath = res.data.data[0].external_folder_path
          buildHierarchy(folderPath).then(hierarchy => updateHierarchy(hierarchy))
          updateExplorer(folderPath);
        } else {
          updateExplorer(`/${ROOT_FOLDER}`);
          setDefaults(true)
        }
      })
      .catch(error => AlertMessage('error', error.message, 3000))
  };

  const fetchRootDirectory = () => {
    updateIsLoading(true)
    if (linkedAccount || selectedAccount) {
      fetchServiceAlias()
    } else if (defaults) {
      updateExplorer(`/${ROOT_FOLDER}`)
    }
  }

  const multipleAccounts = !linkedAccount && accounts?.length > 1

  useEffect(() => {
    if (linkedContact) fetchAccountsByContact()
  }, [linkedContact]);

  useEffect(() => {
    fetchRootDirectory()
  }, [linkedAccount, selectedAccount, defaults]);

  useEffect(() => {
    if (fetchedSmartVaultAccessToken) { fetchAccountId(); }
  }, [fetchedSmartVaultAccessToken]);

  const accountLabels = accounts.map(account => (
    <div className="mt-2 ml-2">
      <RadioButton
        checked={selectedAccount === account.value}
        onChange={() => setSelectedAccount(account.value)}
        buttonFor={account.label}
        buttonValue={account.label}
        buttonField=""
      />
    </div>
  ))

  return (
    <div>
      {defaults && !multipleAccounts && <div style={{ borderRadius: '8px', backgroundColor: '#f5f5f5' }} className="p-3 mb-3">
        Navigate to our
        <Link to="/profile_settings/smartvault_account_links" target="_blank" rel="noopener noreferrer">links page</Link>
        to setup a default SmartVault link to this account.
      </div>}
      {multipleAccounts && <div
        className="px-3 py-2 mb-3"
        style={{ borderRadius: '8px', backgroundColor: '#f5f5f5', fontSize: '.95em' }}
      >
        <div>This file is associated with multiple accounts, please select one to navigate directly to the associated directory.</div>
        {accountLabels}
      </div>}
    </div>
  )
}
