import { WorkflowItem2Fragment, WorkflowRequestFragment } from 'gql/graphql';
import WorkflowSectionWrapper from 'components/Requests/components/WorkflowView/components/WorkflowSectionWrapper/WorkflowSectionWrapper';
import { ItemListItem } from 'components/Requests/components/WorkflowView/components/ItemListItem/ItemListItem';

type TaxDeliverySummaryProps = {
  workflowRequest?: WorkflowRequestFragment | null;
};

export const TaxDeliverySummary = ({
  workflowRequest,
}: TaxDeliverySummaryProps) => {
  let count = 0;
  return (
    <span>
      {workflowRequest?.requestSections?.map((section) => (
        <WorkflowSectionWrapper key={section.id} title={section?.title || ''}>
          {section?.requestItems?.map((requestItem) => {
            count += 1;

            return (
              <ItemListItem
                key={requestItem.id}
                workflowRequestItem={requestItem}
                workflowItem={
                  requestItem.responseItem
                    ?.workflowItem as WorkflowItem2Fragment
                }
                workflowRequest={workflowRequest}
                position={count}
                subPosition={0}
                disableEdit={false}
              />
            );
          })}
        </WorkflowSectionWrapper>
      ))}
    </span>
  );
};
