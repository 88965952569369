import { Typography, Stack, Box } from '@mui/material';
import {
  WorkflowRequestFragment,
  WorkflowWithSectionsFragment,
} from 'gql/graphql';
import {
  useWorkflowRequestQuery,
  useWorkflowRequestsQuery,
} from 'hooks/workflow-request-hooks';
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom-latest';
import LoadingIndicator from 'common/LoadingIndicator';
import { PageProps } from 'ui/Page/Page';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
} from 'components/Requests/requests.constants';
import { DASHBOARD_ROUTE } from 'constants/constants';
import SendRequest from 'components/Requests/components/SendRequest/SendRequest';
import { TaxDeliveryDispatchView } from 'components/Requests/components/TaxDelivery/components/TaxDeliveryDispatchView/TaxDeliveryDispatchView';
import { WorkflowViewer } from './WorkflowViewer';
import { ErrorMessage } from './components/ErrorMessage/ErrorMessage';
import { WorkflowViewWrapper } from './components/WorkflowViewWrapper/WorkflowViewWrapper';
import { WorkflowViewHeader } from './components/WorkflowViewHeader/WorkflowViewHeader';
import { WorkflowViewFooter } from './components/WorkflowViewFooter/WorkflowViewFooter';

export interface WorkflowViewProps extends Omit<PageProps, 'children'> {
  title?: string;
  isDispatch?: boolean;
}

export const WorkflowView = ({
  title,
  isDispatch = false,
}: WorkflowViewProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const template = searchParams.get('template');

  const {
    data: workflowRequestData,
    isInitialLoading: requestLoading,
    error: workflowRequestError,
    isError: isWorkflowRequestError,
  } = useWorkflowRequestQuery(id as string);

  const {
    data: workflowRequestsData,
    isLoading: wfRequestDataIsLoading,
    fetchStatus: wfRequestDataFetchStatus,
  } = useWorkflowRequestsQuery(
    {
      filter: 'my_requests',
    },
    {
      enabled: true,
    },
  );

  const workflowRequest = workflowRequestData?.workflowRequest;

  const workflow = workflowRequest?.workflow;

  // if the logged in user is the assigned user for the request, they can edit the request without prompting
  // assumption atm is if a client can see this request they are the assigned user
  // if the user needs to be prompted to edit we want to start with the input disabled
  const [disableInputState, setDisableInputState] = useState<boolean>(true);
  const [promptEdit, setPromptEdit] = useState<boolean>(false);

  useEffect(() => {
    const wfRequestDataLoading =
      wfRequestDataIsLoading || wfRequestDataFetchStatus !== 'idle';

    const isAssignee = workflowRequestsData?.workflowRequests?.collection.some(
      (request) => request.id === workflowRequest?.id,
    );

    const prompt = wfRequestDataLoading
      ? false
      : !isAssignee && Boolean(workflowRequest) && !isDispatch;

    setPromptEdit(prompt);

    if (isDispatch) {
      setDisableInputState(true);
    } else {
      setDisableInputState(!isAssignee);
    }
  }, [
    isDispatch,
    wfRequestDataFetchStatus,
    wfRequestDataIsLoading,
    workflowRequest,
    workflowRequestsData?.workflowRequests,
  ]);

  const handleGoBack = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate(DASHBOARD_ROUTE);
    }
  };

  const handleEditRequest = () => {
    navigate(
      `${REQUESTS_PAGE_ROUTES.builder}/${workflow?.id}${
        template ? `?prepareOnSave=true` : ''
      }`,
      {
        state: { from: IN_APP },
      },
    );
  };

  const handleSaveAndClose = () => {
    navigate(REQUESTS_PAGE_ROUTES.requests);
  };

  const workflowIsEditable =
    workflowRequest?.rpaDocuments?.length === 0 ||
    workflowRequest?.rpaDocuments === null ||
    !workflowRequest;

  if (requestLoading) {
    return <LoadingIndicator loading />;
  }

  if (isWorkflowRequestError) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        mt={10}
        sx={{ height: 'calc(100vh - 100px)' }}
      >
        {/*  @ts-ignore */}
        <ErrorMessage error={workflowRequestError[0]} />
      </Stack>
    );
  }

  if (!workflow && !workflowRequest) {
    <Stack direction="row" alignItems="center" justifyContent="center" mt={10}>
      <Typography variant="h4">No data found</Typography>
    </Stack>;
  }

  if (workflow?.type === 'TaxDeliveryWorkflow' && isDispatch)
    return <TaxDeliveryDispatchView workflowRequest={workflowRequest} />;

  return (
    <WorkflowViewWrapper
      slots={{
        Header: (
          <WorkflowViewHeader
            workflowRequest={workflowRequest}
            isDispatch={isDispatch}
            promptEdit={promptEdit}
            disableInputState={disableInputState}
            setDisableInputState={setDisableInputState}
            onGoBack={handleGoBack}
            title={title}
          />
        ),
        Footer: (
          <WorkflowViewFooter
            onSubmitDisabled={promptEdit || disableInputState || isDispatch}
            onSaveAndExitDisabled={
              promptEdit || disableInputState || isDispatch
            }
            onCompleteDisabled={!promptEdit}
            workflowRequest={workflowRequest}
          />
        ),
        SideBar: isDispatch ? (
          <Box height="calc(100vh - 86px)">
            <SendRequest
              workflow={workflowRequest?.workflow}
              workflowRequest={workflowRequest}
              onClose={handleSaveAndClose}
            />
          </Box>
        ) : undefined,
      }}
    >
      <WorkflowViewer
        workflow={workflow as WorkflowWithSectionsFragment}
        workflowRequest={workflowRequest as WorkflowRequestFragment}
        disableInputState={disableInputState}
        onEditWorkflowClick={
          isDispatch && workflowIsEditable ? handleEditRequest : undefined
        }
        isDispatch={isDispatch}
        allowResponseEdit={promptEdit}
      />
    </WorkflowViewWrapper>
  );
};
