import { graphql } from 'gql';

export const WorkflowItemConditionFragment = graphql(/* GraphQL */ `
  fragment WorkflowItemCondition on WorkflowItemCondition {
    id
    conditionType
    matchValue
    rangeLowerBound
    rangeUpperBound
  }
`);

export const WorkflowItemConditionFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItemCondition2 on WorkflowItemConditionType2 {
    id
    conditionType
    matchValue
    rangeLowerBound
    rangeUpperBound
    workflowItem {
      id
    }
    items {
      id
      prompt
      responseType
      type
      requestItem {
        ...WorkflowRequestItem2
      }
    }
  }
`);

export const WorkflowItemInterfaceFragment = graphql(/* GraphQL */ `
  fragment WorkflowItemInterface on WorkflowItemInterface {
    id
    prompt
    type
    conditionalParentId
    updatedAt
    position
    section {
      id
    }
    conditions {
      ...WorkflowItemCondition
    }
  }
`);

export const WorkflowItemInterfaceFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItemInterface2 on WorkflowItemInterface2 {
    id
    prompt
    hint
    type
    updatedAt
    position
    section {
      id
    }
    requestItem {
      ...WorkflowRequestItem2
    }
    conditions {
      ...WorkflowItemCondition2
    }
  }
`);

export const WorkflowItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowItem on WorkflowItemInterface {
    ...DefaultWorkflowItem
    ...WorkflowSignableDocumentItem
  }
`);

export const WorkflowItemFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowItem2 on WorkflowItemInterface2 {
    ...DefaultWorkflowItem2
    ...WorkflowSignableDocumentItem2
  }
`);

export const DefaultWorkflowItemFragment = graphql(/* GraphQL */ `
  fragment DefaultWorkflowItem on WorkflowItem {
    ... on WorkflowItem {
      ...WorkflowItemInterface
    }
  }
`);

export const DefaultWorkflowItemFragment2 = graphql(/* GraphQL */ `
  fragment DefaultWorkflowItem2 on WorkflowItemType2 {
    ... on WorkflowItemType2 {
      ...WorkflowItemInterface2
    }
  }
`);

export const WorkflowSignableDocumentItemFragment2 = graphql(/* GraphQL */ `
  fragment WorkflowSignableDocumentItem2 on WorkflowSignableDocumentItemType2 {
    ... on WorkflowSignableDocumentItemType2 {
      ...WorkflowItemInterface2
      esignAgreement {
        id
        documentToSign {
          id
          uuid
          legacyId
          awsUrlFileName
          __typename
        }
        signedDocument {
          id
        }
        signatories {
          id
          label
          status
          signingStatusUrl
          contact {
            id
            lastName
            legacyId
          }
          kbaRequired
          signOnSameDevice
          esignSignatureBlocks {
            id
            pageNumber
            top
            left
            width
            height
          }
        }
        currentUserSignatory {
          id
          contact {
            id
            firstName
            lastName
          }
          firstName
          lastName
          label
          status
          email
          kbaRequired
          signOnSameDevice
          signingUrl
          signingStatusUrl
          esignSignatureBlocks {
            pageNumber
            top
            left
            width
            height
          }
        }
        sameDeviceSignatories {
          id
          contact {
            id
            firstName
            lastName
          }
          firstName
          lastName
          label
          status
          email
          kbaRequired
          signOnSameDevice
          signingUrl
          signingStatusUrl
          esignSignatureBlocks {
            pageNumber
            top
            left
            width
            height
          }
        }
        startSigningUrl
        signingAvailableUrl
      }
    }
  }
`);

export const WorkflowSignableDocumentItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowSignableDocumentItem on WorkflowSignableDocumentItem {
    ... on WorkflowSignableDocumentItem {
      ...WorkflowItemInterface
      esignAgreement {
        documentToSign {
          ...WorkflowRequestDependencyDocument
        }
        signedDocument {
          id
        }
        signatories {
          ...Signers
        }
        signingAvailableUrl
        startSigningUrl
      }
    }
  }
`);

export const deleteWorkflowItemDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowItem($workflowItemId: Uuid!) {
    deleteWorkflowItem(workflowItemId: $workflowItemId) {
      errors {
        message
      }
    }
  }
`);

export const updateWorkflowItemDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowItem(
    $prompt: String
    $type: String
    $workflowItemId: Uuid!
  ) {
    updateWorkflowItem(
      prompt: $prompt
      type: $type
      workflowItemId: $workflowItemId
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem
      }
    }
  }
`);

export const createWorkflowItemMutationDocument = graphql(/* GraphQL */ `
  mutation createWorkflowItem(
    $workflowSectionId: Uuid
    $workflowId: Uuid
    $workflowItemType: String!
    $prompt: String!
    $conditionalParentId: Uuid
  ) {
    createWorkflowItem(
      workflowSectionId: $workflowSectionId
      workflowId: $workflowId
      workflowItemType: $workflowItemType
      prompt: $prompt
      conditionalParentId: $conditionalParentId
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem
      }
    }
  }
`);

export const createWorkflowItemMutationDocument2 = graphql(/* GraphQL */ `
  mutation createWorkflowItem2(
    $workflowSectionId: Uuid
    $workflowId: Uuid
    $workflowItemType: String!
    $prompt: String!
    $conditionId: Uuid
  ) {
    createWorkflowItem2(
      workflowSectionId: $workflowSectionId
      workflowId: $workflowId
      workflowItemType: $workflowItemType
      prompt: $prompt
      conditionId: $conditionId
    ) {
      errors {
        message
      }
      workflowItem {
        ...WorkflowItem2
      }
    }
  }
`);

export const reorderWorkflowItemsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowItems(
    $workflowSectionId: Uuid!
    $workflowItemIds: [Uuid!]!
  ) {
    reorderWorkflowItems(
      workflowItemIds: $workflowItemIds
      workflowSectionId: $workflowSectionId
    ) {
      errors {
        message
      }
      workflowSection {
        ...WorkflowSection
      }
    }
  }
`);

export const reorderWorkflowSectionsMutationDocument = graphql(/* GraphQL */ `
  mutation reorderWorkflowSections(
    $workflowId: Uuid!
    $workflowSectionIds: [Uuid!]!
  ) {
    reorderWorkflowSections(
      workflowId: $workflowId
      workflowSectionIds: $workflowSectionIds
    ) {
      errors {
        message
      }
      workflowSections {
        ...WorkflowSection
      }
    }
  }
`);

export const createWorkflowItemConditionDocument2 = graphql(/* GraphQL */ `
  mutation createWorkflowItemCondition2(
    $workflowItemId: Uuid!
    $conditionType: String!
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    createWorkflowItemCondition2(
      workflowItemId: $workflowItemId
      conditionType: $conditionType
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        id
        conditionType
        matchValue
        rangeLowerBound
        rangeUpperBound
      }
    }
  }
`);

export const updateWorkflowItemConditionDocument2 = graphql(/* GraphQL */ `
  mutation updateWorkflowItemCondition2(
    $workflowItemConditionId: Uuid!
    $conditionType: String
    $matchValue: String
    $rangeLowerBound: String
    $rangeUpperBound: String
  ) {
    updateWorkflowItemCondition2(
      workflowItemConditionId: $workflowItemConditionId
      conditionType: $conditionType
      matchValue: $matchValue
      rangeLowerBound: $rangeLowerBound
      rangeUpperBound: $rangeUpperBound
    ) {
      errors {
        message
      }
      workflowItemCondition {
        ...WorkflowItemCondition2
      }
    }
  }
`);

export const deleteWorkflowItemConditionDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowItemCondition($workflowItemConditionId: Uuid!) {
    deleteWorkflowItemCondition(
      workflowItemConditionId: $workflowItemConditionId
    ) {
      errors {
        message
      }
    }
  }
`);

export const workflowForConditionIdQueryDocument = graphql(/* GraphQL */ `
  query workflowForConditionIdQuery($id: Uuid!) {
    workflow(id: $id) {
      id
      items2 {
        id
        conditions {
          ...WorkflowItemCondition2
        }
      }
    }
  }
`);
